<template>
	<div></div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	name: 'Logout',
	created() {
		this.logout();
		this.resetAll();
		this.$router.push('/');
	},
	methods: {
		...mapMutations(['logout', 'resetAll'])
	}
};
</script>
