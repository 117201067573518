import Joi from 'joi';

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/m;

const phone = Joi.string()
	.regex(phoneRegex)
	.options({
		language: {
			string: {
				regex: {
					base: 'must be a valid phone number'
				}
			}
		}
	});

const auth = {
	login: {
		email: Joi.string()
			.email()
			.required(),
		password: Joi.string()
			.min(7)
			.required()
	},
	register: {
		firstname: Joi.string()
			.min(2)
			.required(),
		lastname: Joi.string()
			.min(2)
			.required(),
		email: Joi.string()
			.email()
			.required(),
		password: Joi.string()
			.min(7)
			.required(),
		phone: phone.required()
	},
	update: {
		firstname: Joi.string()
			.min(2)
			.required(),
		lastname: Joi.string()
			.min(2)
			.required(),
		email: Joi.string()
			.email()
			.required(),
		phone: phone.required()
	}
};

export default auth;
