<template>
	<section class="grid__form">
		<div class="grid__form__header">
			<div class="nav__logo">
				<a href="">
					Admin
				</a>
			</div>
		</div>
		<div class="container">
			<div class="form__wrapper">
				<div class="form__title">
					Login to your Account
				</div>
				<div class="form__body">
					<div
						v-if="getErrorLog.type === 'login' || getStatus === 'error'"
						class="alert"
						:class="{
							'alert-success': getStatus === 'success',
							'alert-danger': getErrorLog.type === 'login' || getStatus === 'error'
						}"
						role="alert"
					>
						{{ getErrorLog.message || message }}
					</div>
					<form @submit.prevent="loginUser" method="post" novalidate>
						<div class="form-row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="form__input__label" for="email">Email Address</label>
									<input
										id="email"
										:class="[{ 'is-invalid': errors.email }]"
										@keydown="clearErrors($event.target.name)"
										v-model="itemData.email"
										class="form-control form__input--lg"
										type="email"
										name="email"
										value=""
										placeholder="Email Address"
										required
									/>
									<span class="form__icon">
										<svg width="20" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 
                          2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z"
												fill="#828282"
											/>
										</svg>
									</span>
									<div class="invalid-feedback" id="emailError">
										{{ errors.email }}
									</div>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="form__input__label" for="password">Password</label>
									<input
										id="password"
										:class="[{ 'is-invalid': errors.password }]"
										@keydown="clearErrors($event.target.name)"
										v-model="itemData.password"
										name="password"
										class="form-control form__input--lg"
										type="password"
										placeholder="Password"
										required
									/>
									<span class="form__icon">
										<svg width="16" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9
                        2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 
                        2-2 2 .9 2 2-.9 2-2 2zm3.1-9H4.9V5c0-1.71 1.39-3.1 3.1-3.1 1.71 0 
                        3.1 1.39 3.1 3.1v2z"
												fill="#828282"
											/>
										</svg>
									</span>
									<div class="invalid-feedback" id="passwordError">
										{{ errors.password }}
									</div>
								</div>
							</div>
						</div>

						<div class="form__group">
							<button
								v-bind="{ disabled: loading }"
								class="btn btn__primary btn__primary--lg"
								id="login-btn"
							>
								<template v-if="!loading"
									>Log In</template
								>
								<template v-else>
									<Loader style="margin-top: -8px" />
								</template>
							</button>
						</div>
					</form>
				</div>
				<div class="form__meta"></div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import form from '../mixins/form';
import Loader from '../components/Loader';
import auth from '../utils/validations/auth';

export default {
	name: 'Login',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: false,
			message: ''
		};
	},
	created() {
		this.resetReq();
	},
	computed: {
		...mapGetters(['isLoggedIn', 'getLoggedUser', 'getErrorLog', 'getStatus'])
	},
	watch: {
		isLoggedIn(value) {
			if (value) {
				setTimeout(() => {
					this.$router.push('/');
				}, 1000);
				this.$Progress.finish();
			}
		},
		getStatus(value) {
			if (value === 'error') {
				this.$Progress.finish();
				this.loading = false;
				this.message = 'Unauthorized Attempt';
			}
		}
	},
	methods: {
		...mapActions(['login']),
		...mapMutations(['resetReq']),
		loginUser() {
			this.$Progress.start();
			this.validate(this.itemData, auth.login);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				this.$Progress.finish();
				return false;
			}
			this.loading = true;
			this.login(this.itemData);
			return true;
		}
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
a {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	font-size: 24px;
	font-weight: bold;
	color: #ffa137;
}
img {
	height: 30px;
	width: auto;
	padding-right: 20px;
}
.form {
	&__link {
		color: #fff;
	}
	&__wrapper {
		padding-bottom: 0;
	}
}
</style>
